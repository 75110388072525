








import { defineComponent } from '@vue/composition-api';
import DefaultNav from '@/layout/components/nav-default/index.vue';
import CookieConcent from '@/layout/components/cookie-consent/index.vue';

export default defineComponent({
  name: 'AppLayoutProtected',
  components: {
    CookieConcent,
    DefaultNav,
  },
});
